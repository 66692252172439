import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { IGraph } from '@shared/repositories/api-counter.repository';

@Injectable({
	providedIn: 'root',
})
export class ExcelService {
	public exportToExcelByInnerText(innerTableText: string, ileName: string): void {
		// Получите данные таблицы
		const tableData: any[] = [];

		// Разделите текст таблицы на строки
		const rows: string[] = innerTableText.split('\n');

		// Получите названия столбцов из первой строки
		const columnNames: string[] = rows[0].split('\t'); // Используйте '\t' если столбцы разделены табуляцией

		// Пройдитесь по остальным строкам таблицы
		for (let i = 1; i < rows.length; i++) {
			const rowData: any = {};
			const cells: string[] = rows[i].split('\t'); // Используйте '\t' если столбцы разделены табуляцией

			// Пройдитесь по ячейкам строки и извлеките данные
			for (let j = 0; j < cells.length; j++) {
				const columnName = columnNames[j];

				rowData[columnName] = cells[j];
			}

			tableData.push(rowData);
		}

		// Вызовите сервис для экспорта таблицы в Excel
		this.exportToExcel(tableData, ileName);
	}

	public exportByDiagramData(diagramData: IGraph[], getOnlyStaticItemsValue: boolean = false, fileName: string = 'table_data'): any[] {
		// Получите данные таблицы
		const tableData: any[] = [];

		diagramData.forEach(value => {
			const rowData: any = {};

			rowData[value.name ? 'Имя' : 'Время'] = value.name ? value.name : this.dateToString(value.intervalDate.start);

			value.staticStatisticItems.forEach(staticStatisticItem => {
				if (!getOnlyStaticItemsValue) {
					rowData[staticStatisticItem.status.title + ',%'] = staticStatisticItem.percent;
					rowData[staticStatisticItem.status.title] = this.msToTime(staticStatisticItem.absolute);
				} else {
					rowData[staticStatisticItem.status.title] = staticStatisticItem.value;
				}
			});

			value.lineItems.forEach(lineItem => {
				rowData[lineItem.name] = lineItem.value;
			});

			value.splineItems.forEach(splineItem => {
				const splineItemName = splineItem.name;
				splineItem.lineItems.forEach(lineItem => {
					rowData[splineItemName + '. ' + lineItem.name] = lineItem.value;
				});
			});

			tableData.push(rowData);
		});

		this.exportToExcel(tableData, fileName);

		return tableData;
	}

	public exportToExcel(data: any[], fileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveAsExcelFile(excelBuffer, fileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url: string = window.URL.createObjectURL(data);
		const link: HTMLAnchorElement = document.createElement('a');
		link.href = url;
		link.download = fileName + '.xlsx';
		link.click();
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			link.remove();
		}, 100);
	}

	private msToTime(duration): string {
		if (!duration) {
			return '';
		}
		const milliseconds = parseInt('' + ((duration % 1000) / 100));
		const seconds = parseInt('' + ((duration / 1000) % 60));
		const minutes = parseInt('' + ((duration / (1000 * 60)) % 60));
		const hours = parseInt('' + ((duration / (1000 * 60 * 60)) % 24));
		const day = parseInt('' + ((duration / (1000 * 60 * 60 * 24))));

		return day + 'д. ' + hours + ' ч. ' + minutes + 'мин. ' + seconds + 'сек.';
	}

	private dateToString(date: Date): string {
		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}
}
